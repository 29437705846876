<template>
  <div class="user-terms-and-condition pt-3">
    <h2
      v-if="dataObj.termsAndCondition"
      class="text-center"
    >
      Terms and Conditions
    </h2>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-html="dataObj.termsAndCondition" />
    <h2
      v-if="dataObj.refundPolicy"
      class="text-center"
    >
      Refund Policy
    </h2>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-html="dataObj.refundPolicy" />
  </div>
</template>

<script>
import { useUserUi } from './useUser'

export default {
  data() {
    return {
      dataObj: {
        terms: '',
        refundPolicy: '',
      },
    }
  },
  mounted() {
    const { termsAndConditionDetail } = useUserUi()

    termsAndConditionDetail(`?id=${this.$route.params.storeId ? this.$route.params.storeId : this.$route.params.providerId}&type=${this.$route.params.storeId ? 'SHOP' : 'PROVIDER'}&refund_policy&terms`).then(({ data }) => {
      if (data.statusCode === '200') {
        this.dataObj = {
          refundPolicy: data.responseData.refund_policy,
          termsAndCondition: data.responseData.terms,
        }
      }
    })
  },
}
</script>
